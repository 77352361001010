import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { registerAccount } from '../Actions/WebsiteActions'
import { clearCartItems } from '../Actions/CartActions'
import Header from './Common/Header'
import Footer from './Common/Footer'

const mapStateToProps = (state) => {
    return {
        supplierSignUpComplete: state.Website.supplierSignUpComplete,
    }
}

const mapDispatchToProps = dispatch => ({
    createAccount: (data, UserType) => dispatch(registerAccount(data, UserType)),
    clearCartItems:  () => dispatch(clearCartItems())
});

class PaymentComplete extends Component {

    constructor(props) {
        super(props);

        this.state = {
            first_name: null,
            last_name: null,
            email: null,
            password: null,
            confim_password: null

        }
        this.props.clearCartItems()
    }

    handleUpdate = (event, field) => {
        this.setState({
            [field]: event.target.value
        })
    }

    doRegistration = () => {
        this.props.createAccount(this.state, 2);
    }

    CustomerLogin = () => {
        window.location.href = window.location.origin + "/login";
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return <Fragment>
            <Header />
            <section className="gry-log">
                <div className="row">
                    <div className="container">
                        <div className="col l12 m12 s12">
                            <div className="log-head">
                                <h1>Thank you!</h1>
                                <p>Payment complete, please <a style={{ cursor: 'pointer' }} onClick={this.CustomerLogin}>  login</a> to your account to manage your booking.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PaymentComplete);