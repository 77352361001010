import React, { PureComponent, Fragment } from 'react'
import StarRatingComponent from 'react-star-rating-component';


class SearchResultListGridItem extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            searchedLocations: []
        }
    }

    getTripType(type) {

        switch (type) {
            case 1: { return "Round tour" }
            case 2: { return "Day Tour" }
            case 3: { return "Activity" }
            case 4: { return "Transport" }
        }
    }



    render() {
        return (
            <div >
                <div className="grid-wv tour-list">

                    {this.props.data.average_rating != null ?
                        <div class="tour-rating-badge"><span>{parseFloat(this.props.data.average_rating).toFixed(1)}</span>Rating</div> :
                        <div class="tour-rating-badge"><span>No</span>Ratings yet</div>
                    }
                    <div className="img ">
                        {this.props.data.deals !== undefined ? (<span class="off"><p>{this.props.data.deals.subscription.deals.discount}%</p></span>) : null}
                        {this.props.data.cover_image !== null ? (
                            <img src={this.props.data.cover_image !== null ? this.props.data.cover_image : null} />
                        ) : null}
                        {/* <span className="off">
                            <p>40%</p>
                        </span> */}
                    </div>
                    <div className="cont">
                        <h6>{this.props.data.title.substr(0, 48)}{this.props.data.title.length > 48 ? '...' : null}</h6>
                        <p>{this.props.data.overview.substr(0, 90)}...</p>
                        <div>
                            <p><span>CATEGORIES	</span>{this.props.data.category[0].category.name} {this.props.data.category.length > 1 ? (<em>+{this.props.data.category.length - 1} more</em>) : null} </p>
                            {this.state.searchedLocations.length > 0 ? (<p><span>PICK-UP LOCATIONS	</span> &nbsp;{this.getPickupLocations()}</p>) : null}
                            <p><span>TOUR TYPE	</span></p>
                        </div>
                    </div>
                    <div className="tou-fot">
                        <div>
                            <div className="reviews2">
                                <StarRatingComponent
                                    name="rate2"
                                    editing={false}
                                    starCount={5}
                                    value={this.props.data.average_rating}
                                    renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal' }} >★</i>}
                                />
                                <span className="reviews" style={{ fontSize: 12 }}>Reviews ({this.props.data.rating_count})</span>
                            </div>
                        </div>
                        <div className="pric-side">
                            <span className="price">USD {this.props.data.price.selling_price}</span>
                            <span className="st-price">Starting Price</span>
                        </div>
                        <div className="clear"></div>
                    </div>

                </div>
            </div>
        );
    }
}

SearchResultListGridItem.defaultProps = {
    home: false,
}


export default (SearchResultListGridItem);
