export default function reducer(state={
    loading:false,
    showAlert:false,
    alertMessage:null,
    alertVarient:'info',    
    emailConfirmation:false,
    emailConfirmData:null,
    onEmailConfirm:null,
    show_inquare:false,
    inquaries:[],
    mostPopularRoudToursLoading:false,
    mostPopularDayToursLoading:false,
    featuredSearchedItemsLoading:false,
    bannersLoading:false,
    normalSearchedItemsLoading:false,
    locationPage: []
},action){
switch(action.type){
    case "LOADIND_START":{
        return{...state,loading:true}
    }
    case "LOADIND_END":{ 
        return{...state,loading:false}
    }
    case "SHOW_ALERT":{
        return{...state,showAlert:action.show,alertMessage:action.message,alertVarient:action.varient}
    }    
    case "CONFIRM_EMAIL":{
        return{...state,emailConfirmation:action.show,emailConfirmData:action.data,onEmailConfirm:action.onConfirm}
    }
    case "SHOW_INQUIRE":{
        return {...state,show_inquare:action.show}
    }  
    case "STORE_INQUIRE":{ 
        return{...state,inquaries:action.inquaries}
    }
    case "SHOW_LOADER":{
        return {...state,[action.field]:action.status}
    }
    case "STORE_LOCATION_TAGS": {
        return {...state,locationPage:action.locationPage}
    }
}
return state
}