import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/Footer';
import CartItem from './Common/CartItem';
import { Helmet } from "react-helmet";
import { CartRemoveItem, GetCartTotal } from '../Actions/CartActions';
import { Link } from 'react-router-dom'

const mapStateToProps = (state) => {
	return {
		CartItems: state.Cart.items,
		CartTotal: GetCartTotal(state.Cart.items),
		CartTotalFormatted: state.Cart.totalFormatted,
	}
}

const mapDispatchToProps = dispatch => ({
	CartRemoveItem: (ItemID) => dispatch(CartRemoveItem(ItemID))
})

class ShowCart extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
		}
	}

	componentDidUpdate = () => {
		if (this.props.CartItems.length === 0) {
			window.location.href = "/search?key=";
		}
	}

	render() {

		const { CartTotal } = this.props;
		return <Fragment>
			<Header />
			<Helmet>
				<meta charSet="utf-8" />
				<title>Cart</title>
			</Helmet>
			<section className="body-gry">
				<div className="container">
					<div className="row">
						<div className="s12">
							<div className="shopping-cart">
								<div className="hed-bed">
									<div className="bred-cmp">
										<a href="">Home </a> / <span>Cart</span>
									</div>
									<h1>Cart</h1>
								</div>
								<div className="mrg-set">
									<div className="row">
										<div className="col l8 m8">
											<div className="lef-shp">
												{this.props.CartItems.map((item, index) => {
													return <CartItem onRemoveItem={this.props.CartRemoveItem} data={item} itemIndex={index} />
												})}


											</div>
										</div>
										<div className="col l4 m4 s12">
											<div className="pay-div">
												<div className="crd">
													<span>Cart Total</span>
													<img src="/img/Visa-Logo.png" />
												</div>
												<div className="py-pric">
													<div>USD {CartTotal}</div>
													<span>No additional taxes & fees</span>
												</div>
												<div className="clear"></div>
												<div className="submit">
													<i className="waves-effect waves-light btn">
														<Link to={'/checkout'}><input type="submit" value="Check out" name="" /></Link>
													</i>

												</div>
											</div>
											<div className="hr"></div>
											<div className="low-pr">
												<h6>Lowest Price Guarantee</h6>
												<p>Find it cheaper online? We'll refund the difference</p>
												<h6>24/7 Global Support</h6>
												<p>Get the answers you need, when you need them</p>
											</div>
											<div className="hr"></div>
											<div className="cal-sp">
												<a >
													<span>For Questions call us</span>
													+94 705 579 679
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>

			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ShowCart);