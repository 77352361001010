import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/Footer';
import { Helmet } from "react-helmet";
import SearchResultGridItem from './Common/SearchResultGridItem';
import { GetLocationContent } from '../Actions/WebsiteActions';
import Alert from './Common/Alert';

const $ = window.jQuery;

const mapStateToProps = (state) => {
	return {
		loading: state.Common.loading,
		loggedIn: state.Auth.loggedIn,
		aboutus: state.Website.site_aboutus,
		activePage: state.Website.activePage,
	}
}

const mapDispatchToProps = dispatch => ({
	GetLocationContent: (pageId) => dispatch(GetLocationContent(pageId))
});
class LocationPage extends Component {

	owlPopularDayTours = React.createRef();

	constructor(props) {
		super(props);

		const { pid } = this.props.match.params;

		this.state = {
			username: "",
			password: "",
			loggedIn: false,
			loading: false,
			id: 0,
			location: "",
			title: "",
			description: "",
			seo_title: "",
			seo_description: "",
			seo_url: "",
			full_seo_url: "",
			image_alt: "",
			activities: [],
			image_name: null,
			uploadImage: null,
			location_data: null,
			dayToursLoaded: false
		};

		if (pid !== null && pid !== undefined) {
			this.props.GetLocationContent(pid);
		}

	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});
	};


	componentWillReceiveProps = (nextProps) => {
		this.setState({
			id: nextProps.activePage.id,
			location: nextProps.activePage.location,
			title: nextProps.activePage.title,
			description: nextProps.activePage.description,
			seo_title: nextProps.activePage.seo_title,
			seo_description: nextProps.activePage.seo_description,
			seo_url: nextProps.activePage.seo_url,
			full_seo_url: nextProps.activePage.full_seo_url,
			image_alt: nextProps.activePage.image_alt,
			activities: nextProps.activePage.activities,
			image_name: nextProps.activePage.image_name,
			location_data: nextProps.activePage.location_data
		});
	}

	componentDidUpdate = () => {



		if (this.state.activities.length > 0 && this.state.dayToursLoaded === false) {

			$(this.owlPopularDayTours.current).owlCarousel({
				loop: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 0
					},
					600: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 10,
					},
					1000: {
						items: 4,
						loop: false,
						nav: false,
						dote: false,
						margin: 30,
					}
				}
			});

			this.setState({
				dayToursLoaded: true
			})
		}




	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}


	render() {

		var dayTours = 0;
		let numItems = 1;
		return <Fragment>
			<Header home={false} />
			<Helmet>
				<meta charSet="utf-8" />
				<title>{this.state.seo_title}</title>
				<meta name="title" content={this.state.seo_description} />
				<meta name="description" content={this.state.seo_description} />
				<meta property="og:title" content={this.state.seo_title} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={this.state.full_seo_url} />
				<meta property="og:image" content={this.state.image_name} />
			</Helmet>
			<Alert />
			<section className="bedcmp-section">
				<div className="container">
					<div className="row">
						<div className="col l12">
							<div className="hed-bed">
								<div className="bred-cmp">
									<a href="">Home</a> / <span>{this.state.location_data !== null ? this.state.location_data.name : null}</span> / <span>{this.state.location_data !== null ? this.state.title : null}</span>
								</div>
								<h1>{this.state.title}</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container">
					<div className="row">
						<div className="col l7 m7 s12">
							<div className="abou-cont">
								<p>{this.state.description}</p>

							</div>
						</div>
						<div className="col l5 m5 s12">
							<div className="abot-img">
								<img src={this.state.image_name} style={{ display: 'block', width: '100%', height: 'auto', position: 'relative' }} />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="abou-gry">
				{this.state.location_data !== null ? <h4 className='location-pages-h4'>Activities & Tours starting from {this.state.location_data.name}</h4> : null}
				<div className="container">
					<div className="row">
						{this.state.activities.length != 0 ? this.state.activities.map((item, index) => {
							numItems++;
							return <Fragment>{numItems === 1 ? (<div style={{ clear: 'both' }} />) : null}
								<div style={{ "position": "relative", "padding": 0 }} className="col xl3 l3 m6 s12 margin-top-20">
									<SearchResultGridItem searchParams={this.props.searchParams} data={item} />
								</div></Fragment>;
						}) : null}
					</div>
					<div className="row">
						<div className="col l7">
							<div className="row">
								<div className="col l6 s12">
									<div className="cont-abou">
										<h5>Connect with Us!</h5>
										<p>Want to know more? Join us </p>
									</div>
								</div>
								<div className="col l6 s12">
									<div className="cont-abou">
										<ul>
											<li><a className="fb" href=""></a></li>
											<li><a className="lin" href=""></a></li>
											<li><a className="twi" href=""></a></li>
											<li><a className="you" href=""></a></li>
											<li><a className="pin" href=""></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(LocationPage);
