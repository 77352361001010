import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

class RefundPolicy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.show}
                    keepMounted
                    fullWidth={true}
                    // onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">Refund Policy</DialogTitle>
                    <DialogContent>
                        {/* <p> */}
                        If the applicable Operator does not confirm the requested booking, or, -as a Traveller, you cancel your requested booking before the requested booking is confirmed by an Operator, any amounts collected by Aktivitar or its third party payment processor will be refunded to the Traveller within a commercially reasonable time, depending on the selections the Traveller made via the Site and Application, and any pre-authorization of such Traveler’s credit/debit card will be released. Once your booking is confirmed by the applicable Operator, the Total Cost of your Tour is subject to the cancellation and payment terms of the Operator. Please carefully read the fine print and important information in the Listing, the booking page and your reservation confirmation for policies as applied by the Operator. A cancellation must be done via your booking conversation page.
                        {/* </p> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>);
    }

}


export default RefundPolicy;
