export default function reducer(state = {
    list: [],
    total: 0,
    totalFormatted: "",
    bookNow: false,
    items: []

}, action) {
    switch (action.type) {
        case "STORE_CART_ITEMS": {
            return { ...state, list: action.list, total: action.total, totalFormatted: action.totalFormatted }
        }
        case "LOADIND_END": {
            return { ...state, loading: false }
        }
        case "UPDATE_TOUR": {
            return { ...state, activeTour: action.tour }
        }
        case "UPDATE_ESTIMATION": {
            return { ...state, activeEstimation: action.amount }
        }
        case "BOOK_NOW": {
            return { ...state, bookNow: action.bookNow }
        }
        case "ADD_ITEM": {
            return { ...state, items: [...state.items, action.item], bookNow: action.item.bookNow }
        }
        case "REMOVE_ITEM": {
            return { ...state, items: [...state.items.slice(0, action.index), ...state.items.slice(action.index + 1)] }
        }
        case "CLEAR_CART_ITEMS": {
            return { ...state, items: [] }
        }
        case "UPDATE_CART_ITEM": {
            return {
                ...state, items: Object.values({
                    ...state.items,
                    [action.index]: {
                        ...state.items[action.index],
                        from_date: action.data.pud,
                        location_id: action.data.pul,
                        adults: action.data.na,
                        children: action.data.nc,
                        infants: action.data.ni,
                        amount: action.amount,
                    }
                })
            }
        }
    }
    return state
}