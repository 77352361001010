import React, { Props, Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Inquary from '../Common/InquiryPopup';
import { showInquire, getLocationTags } from '../../Actions/CommonActions';
import { compose } from 'redux';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
	return {
		show: state.Common.show_inquare,
		common: state.Common,
	}
}
const mapDispatchToProps = dispatch => ({
	showInquire: (data) => dispatch(showInquire(data)),
	getLocationTags: () => dispatch(getLocationTags())
});

const getTourLabel = (count) => {
	var label = " tour"
	if (count > 1) {
		label = " tours"
	}
	return label
}
class Footer extends Component {

	constructor(props) {
		super(props);

		this.state = {

			show_inquare: false,
		};
		this.props.getLocationTags();
	}
	// componentDidMount() {
	// 	const script = document.createElement("script");
	// 	script.src = "/js/app.min.js";
	// 	script.async = true;
	// 	script.onload = () => this.scriptLoaded();
	// 	document.body.appendChild(script);
	// }

	render() {
		return (
			<Fragment>
				{this.props.common.locationPage.length > 0 ?
					<div className="row location_tag">
						<h4>Tourist destinations and cities in Sri lanka</h4>
						{this.props.common.locationPage.sort((a, b) => b.aktivity_count - a.aktivity_count).map((location) => (
							<div className="col tags l3 s6 m4">
								<p style={{ cursor: "pointer" }}><a href={location.full_seo_url}>{location.location_data.name}</a></p>
								<span>{location.aktivity_count > 0 ? location.aktivity_count + getTourLabel(location.aktivity_count) : null}</span>
							</div>
						))}
					</div> : null}
				<div className="gar-fot">
					<div className="container">
						<div className="row">
							<div className="col l4 s12">
								<div className="fot-cont">
									<a href="">
										<img src="/img/logo.png" />
									</a>
									<p>
										Aktivitar is a registered trademark of <br />
										AKTIVITAR (PVT) LTD.<br />
										Use of this website constitutes acceptance
										of Aktivitar's Terms & Conditions.
									</p>
								</div>

							</div>
							<div className="col l4 m6 s12">
								<div className="fot-menu">
									<div>
										<h4>Help center</h4>
										<ul>
											<li><a href="/help">Customer care</a></li>
											<li><a href="/help/0">Privacy policy</a></li>
											<li><a href="/help/2">Cookie policy</a></li>
											<li><a href="/help/1">Product desclaimers</a></li>
										</ul>
									</div>
									<div>
										<h4>Sitemap</h4>
										<ul>
											<li><Link to="/about">About us</Link></li>
											<li> <a style={{ display: 'inline' }} href="/signup"> Supplier : Sign-Up or</a><a style={{ display: 'inline' }} href="https://supplier.aktivitar.com/"> Login</a> </li>

										</ul>
									</div>
								</div>
							</div>
							<div className="col l4 m6 s12">
								<div className="for-cont">
									<div className="yelo">
										<span>Call For Inquiries</span>
										<a className="call" href="tel:+94 705 579 679"> +94 705 579 679 </a>
										<div onClick={() => { this.props.showInquire(true) }} >  Inquire Now   </div>
									</div>
									<span>Get the latest Updates</span>
									<ul>
										<li><a className="fb" href="https://www.facebook.com/aktivitar" target="_blank"></a></li>
										<li><a className="you" ></a></li>
										<li><a className="pin" href="https://www.instagram.com/aktivitar/" target="_blank"></a></li>

									</ul>
									<Inquary />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="fot-bot">
					<div className="container">
						<div className="row">
							<div className="col s12">
								<div className="copy">
									<p>Copyright 2018 Aktivitar. All Rights Reserved. <a target="_blank" href="https://www.extremewebdesigners.com/services/web-design-sri-lanka/">Design</a> by<a target="_blank" href="http://extremewebdesigners.com/"><img src="https://extremewebdesigners.com/images/extreme-web-design-sri-lanka.png" /> EWD</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Footer);
