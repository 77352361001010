import React, { Props, Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component';


class SearchResultGridItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            searchedLocations: []
        }
    }

    getTripType(type) {

        switch (type) {
            case 1: { return "Round tour" }
            case 2: { return "Day Tour" }
            case 3: { return "Activity" }
            case 4: { return "Transport" }
        }
    }

    componentDidMount = () => {
        if (this.props.searchParams !== undefined && this.props.searchParams.location !== "") {
            this.setState({
                searchedLocations: this.props.searchParams.location.split(',')
            });
        }

    }

    componentDidUpdate = () => {
        this.getPickupLocations();
    }

    getPickupLocations = () => {
        if (this.props.data != null && this.props.data != undefined && this.state.searchedLocations !== undefined && this.props.data.available_locations !== undefined) {
            var matchedLocations = [];
            for (var x = 0; x < this.props.data.available_locations.length; x++) {
                if (this.state.searchedLocations.includes(this.props.data.available_locations[x].loc_id.toString())) {
                    matchedLocations.push(this.props.data.available_locations[x]);
                }
            }
            if (matchedLocations.length > 0) {
                return <Fragment>{matchedLocations[0].location_name}&nbsp;{matchedLocations.length > 1 ? (<em>+{matchedLocations.length - 1} more</em>) : null}</Fragment>;
            }
        }
    }

    render() {
        return (
            <div className="item set-cursor" onClick={() => window.location.href = '/activity/details/' + this.props.data.id} >
                <div className="grid-wv tour-list">

                    <div className="img ">
                        {this.props.data.deals !== undefined ? (<span class="off"><p>{this.props.data.deals.subscription.deals.discount}%</p></span>) : null}
                        {this.props.data.cover_image !== null ? (
                            <img src={this.props.data.cover_image !== null ? this.props.data.cover_image : null} />
                        ) : null}
                    </div>
                    {this.props.data.average_rating != null ?
                        <div class="tour-rating-badge"><span>{parseFloat(this.props.data.average_rating).toFixed(1)}</span>Rating</div> :
                        <div class="tour-rating-badge"><span>No</span>Ratings yet</div>
                    }
                    <div className="cont">
                        <h6>{this.props.data.title !== undefined ?
                            <> {this.props.data.title.substr(0, 48)}{this.props.data.title.length > 48 ? '...' : null}</> : null}
                        </h6>
                        <p>{this.props.data.overview !== undefined ? <>{this.props.data.overview.substr(0, 90)}...</> : null}</p>
                        <div>
                            {this.props.data.category !== undefined ? <p><span>CATEGORIES	</span>{this.props.data.category[0].category.name} {this.props.data.category.length > 1 ? (<em>+{this.props.data.category.length - 1} more</em>) : null} </p> : null}
                            {this.state.searchedLocations.length > 0 ? (<p><span>PICK-UP LOCATIONS	</span> &nbsp;{this.getPickupLocations()}</p>) : null}
                            <p><span>TOUR TYPE	</span>{this.getTripType(this.props.data.tour_type)}</p>
                        </div>
                    </div>
                    <div className="tou-fot">
                        <div>
                            <div className="reviews2">
                                <StarRatingComponent
                                    name="rate2"
                                    editing={false}
                                    starCount={5}
                                    value={this.props.data.average_rating}
                                    renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal' }} >★</i>}
                                />
                                <span className="reviews" style={{ fontSize: 12 }}>Reviews ({this.props.data.rating_count})</span>
                            </div>
                        </div>
                        <div className="pric-side">
                            <span className="price">USD {this.props.data.price != undefined ? this.props.data.price.selling_price : null}</span>
                            <span className="st-price">Starting Price</span>
                        </div>
                        <div className="clear"></div>
                    </div>

                </div>
            </div>
        );
    }
}

SearchResultGridItem.defaultProps = {
    home: false,
}


export default (SearchResultGridItem);
